function openDialog(dialog) {
  dialog.prev(".dialog__shade").fadeIn("fast");
  dialog.css("display", "flex").hide().fadeIn("fast");
}

function closeDialog(dialog) {
  dialog.prev(".dialog__shade").fadeOut();
  dialog.fadeOut();
}

$("#filterTrigger").on("click", function() {
  openDialog($("#filterDialog"));
});

$("#filterClose").on("click", function() {
  closeDialog($("#filterDialog"));
});
