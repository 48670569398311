//------------------//
// Matrix accordion //
//------------------//

var accordionCount = 1;

$(".matrix--accordion").each(function () {
  var $this = $(this);

  $this.find(".accordion__trigger").attr({
    id: "accordionTrigger" + accordionCount,
    "aria-controls": "accordionBody" + accordionCount,
  });

  $this.find(".accordion__body").attr({
    id: "accordionBody" + accordionCount,
    "aria-labelledby": "accordionTrigger" + accordionCount,
  });

  return accordionCount++;
});

$(".accordion__trigger").on("click", function () {
  var $this = $(this);
  var parent = $this.parents(".matrix--accordion");
  var body = parent.find(".accordion__body");

  if (parent.hasClass("expanded")) {
    $this.attr("aria-expanded", "false");
    body.slideUp("fast");
  } else {
    $this.attr("aria-expanded", "true");
    body.slideDown("fast");
  }

  parent.toggleClass("expanded");
});

//--------------------//
// Generic expandable //
//--------------------//

var expandablesCount = 1;

$(".expandable").each(function () {
  var $this = $(this);

  $this.find(".expandable__trigger").attr({
    id: "expandableTrigger" + expandablesCount,
    "aria-controls": "expandableBody" + expandablesCount,
  });

  $this.find(".expandable__body").attr({
    id: "expandableBody" + expandablesCount,
    "aria-labelledby": "expandableTrigger" + expandablesCount,
  });

  return expandablesCount++;
});

$(".expandable__trigger").on("click", function () {
  var $this = $(this);
  var parent = $this.parents(".expandable");
  var body = parent.find(".expandable__body");

  if (parent.hasClass("expanded")) {
    $this.attr("aria-expanded", "false");
    body.slideUp("fast");
  } else {
    $this.attr("aria-expanded", "true");
    body.slideDown("fast");
  }

  parent.toggleClass("expanded");
});

//--------------//
// Agenda group //
//--------------//

var agendaGroupCount = 1;

$(".agenda-group__trigger").each(function () {
  var $this = $(this);
  var body = $this.parent().siblings(".agenda-group__body");

  $this.attr({
    id: "agendaGroupTrigger" + agendaGroupCount,
    "aria-controls": "agendaGroupBody" + agendaGroupCount,
  });

  if ($this.parents(".agenda-group").hasClass("agenda-group--exposed")) {
    $this.attr("aria-expanded", "true");
  } else {
    $this.attr("aria-expanded", "false");
  }

  body.attr({
    id: "agendaGroupBody" + agendaGroupCount,
    "aria-labelledby": "agendaGroupTrigger" + agendaGroupCount,
  });

  return agendaGroupCount++;
});

$(".agenda-group__trigger").on("click", function () {
  var $this = $(this);
  var parent = $this.parents(".agenda-group");
  var body = $this.parent().siblings(".agenda-group__body");

  if (parent.hasClass("agenda-group--exposed")) {
    $this.attr("aria-expanded", "false");
    body.slideUp("fast");
  } else {
    $this.attr("aria-expanded", "true");
    body.slideDown("fast");
  }

  parent.toggleClass("agenda-group--exposed");
});

//----------------------//
// Objectives read more //
//----------------------//

$(".objectives__trigger").on("click", function () {
  var $this = $(this);
  var objectivesList = $this.siblings(".objectives-list");

  objectivesList.children().each(function () {
    if ($(this).hasClass("objective--collapsed")) {
      $(this).slideDown("fast");
      $(this).removeClass("objective--collapsed");
    }
  });

  $this.hide();
});

//----------------------//
// Descriptions read more //
//----------------------//

$(".descriptions__trigger").on("click", function () {
  var $this = $(this);
  var descriptHidden = $this.siblings(".description--collapsed");

  descriptHidden.slideDown("fast");
  descriptHidden.removeClass("description--collapsed");

  $this.hide();
});

//----------------------//
// Downloads read more //
//----------------------//

$(".downloads__trigger").on("click", function () {
  var $this = $(this);
  var objectivesList = $this.siblings(".downloads-list");

  objectivesList.children().each(function () {
    if ($(this).hasClass("download-item--collapsed")) {
      $(this).slideDown("fast");
      $(this).removeClass("download-item--collapsed");
    }
  });

  $this.hide();
});
