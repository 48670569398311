const navBreakpoint = 900;
const mobileNavButton = $("#mobileNavButton");
const mainNav = $("#mainNav");

//------------------------//
// Mobile expand-collapse //
//------------------------//

function collapseMobileMenu() {
  mainNav.slideUp("fast");
  mobileNavButton.removeAttr("aria-expanded");
  mobileNavButton.children("span").text("Menu");
}

function expandMobileMenu() {
  mainNav.slideDown("fast");
  mobileNavButton.attr("aria-expanded", "true");
  mobileNavButton.children("span").text("Close");
}

mobileNavButton.on("click", function () {
  mobileNavButton.attr("aria-expanded")
    ? collapseMobileMenu()
    : expandMobileMenu();
});

//--------------------------//
// Submenus expand-collapse //
//--------------------------//

$("#mainNav a").on("click", function (e) {
  const $this = $(this);
  const subMenu = $this.siblings(".level-2-nav-wrapper");
  const subMenuUl = subMenu.find(".level-2-ul");

  if (subMenu.length) {
    e.preventDefault();

    // Collapse all other submenus
    $(".nav-item--level-1[aria-haspopup='true']").not(this).each(function() {
      $(this).siblings(".level-2-nav-wrapper").slideUp("fast");
      $(this).attr("aria-expanded", "false");
    })

    // Expand this item's sub menu
    if ($this.attr("aria-expanded") == "false") {
      subMenu.slideDown("fast");
      $this.attr("aria-expanded", "true");

      // If we are on desktop, do some sniffing to align things
      // Agree this is a bit fiddly
      if($(window).width() >= navBreakpoint) {
        subMenuUl.css("margin-right", $("#mainNav").width() - subMenuUl.width());
      }

    } else {
      subMenu.slideUp("fast");
      $this.attr("aria-expanded", "false");
    }
  }
});

//-----------------------------//
// Resizing across breakpoints //
//-----------------------------//

let prevWindowWidth = 0;

$(document).ready(function () {
  prevWindowWidth = $(window).width();
});

$(window).resize(function () {
  let currentWindowWidth = $(window).width();

  // If we're going from either desktop to mobile or mobile to desktop
  // just tidy everything up
  if (
    (prevWindowWidth > navBreakpoint && currentWindowWidth <= navBreakpoint) ||
    (prevWindowWidth <= navBreakpoint && currentWindowWidth > navBreakpoint)
  ) {
    mainNav.css("display", "");
    mainNav.find(".level-2-nav-wrapper").css("display", "");
    mainNav.find("a[aria-expanded='true']").attr("aria-expanded", "false");
    mainNav.find(".level-2-ul").css("margin-right", "");
    mobileNavButton.removeAttr("aria-expanded");
    mobileNavButton.children("span").text("Menu");
  }

  prevWindowWidth = currentWindowWidth;
});
